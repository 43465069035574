import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  getCustomerData,
  getCustomerById,
  getServicesByCategoryId,
  getPaymentTypes,
  saveCustomerDetails,
  saveVehicleDetails,
  saveBooking,
} from "../services/api";
import {
  Typography,
  Button,
  TextField,
  InputAdornment,
  Autocomplete,
  Select,
  InputLabel,
  MenuItem,
  Chip,
  Checkbox,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  FormControlLabel,
  FormControl,
  FormLabel,
  FormHelperText,
  FormGroup,
  Grid,
  CircularProgress,
  Divider,
  Snackbar,
  Alert,
} from "@mui/material";
import {
  CalendarMonthOutlined as CalendarMonthOutlinedIcon,
  DeleteForever as DeleteForeverIcon,
  AccountCircle as AccountCircleIcon,
  LocalCarWash as LocalCarWashIcon,
  CurrencyRupee as CurrencyRupeeIcon,
  DiscountOutlined as DiscountOutlinedIcon,
  AddCircle as AddCircleIcon,
  HeadphonesBattery as HeadphonesBatteryIcon,
  CleaningServices as CleaningServicesIcon,
  CarRental as CarRentalIcon,
  RemoveCircleOutlineRounded as RemoveCircleOutlineRoundedIcon,
} from "@mui/icons-material";

/** Dialog */
import CustomerForm from "../customer/Customer";
import VehicleForm from "../vehicle/Vehicle";
const Booking = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [customers, setCustomers] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [paymentTypes, setPaymentTypes] = useState([]);
  const [selectedPayments, setSelectedPayments] = useState([]);
  const [vehicles, setVehicles] = useState([]);
  const [selectedVehicle, setSelectedVehicle] = useState(null);
  const [services, setServices] = useState([]);
  const [selectedServices, setSelectedServices] = useState([]);
  const [accessoryAmount, setAccessoryAmount] = useState(0);
  const [discountAmount, setDiscountAmount] = useState(0);
  const [servicesTotal, setServicesTotal] = useState(0);
  const [total, setTotal] = useState(0);
  const [openDialog, setOpenDialog] = useState(false);
  const [openVehicleDialog, setOpenVehicleDialog] = useState(false);
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [severityType, setSeverityType] = useState('success');

  const handleToastClick = (message, statusType) => {
    setOpen(true);
    setSeverityType(statusType);
    setMessage(message);
  };

  const handleToastClose = () => {
    setOpen(false);
  };

  const currentDate = new Date();
  const formattedDate = currentDate.toLocaleDateString("en-US", {
    year: "numeric",
    month: "long",
    day: "numeric",
  });

  // Error states & props
  const [customerError, setCustomerError] = useState(false);
  const [paymentError, setPaymentError] = useState(false);
  const [amountPaidError, setAmountPaidError] = useState(false);

  useEffect(() => {
    fetchCustomerData();
    fetchPaymentData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    calculateServiceTotal();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedServices]);

  useEffect(() => {
    calculateOverallTotal();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [servicesTotal, accessoryAmount, discountAmount]);

  const openCustomer = () => setOpenDialog(true);

  const openVehicle = () => setOpenVehicleDialog(true);

  // API calls section
  const submitCustomerDetails = async (customerData) => {
    try {
      setLoading(true);
      const customerDetails = await saveCustomerDetails(customerData);

      if (customerDetails.success) {
        handleToastClick(customerDetails.message, 'success');
        const customers = await getCustomerData();
        setCustomers(customers);
        const customerByIdDetails = await getCustomerById(customerDetails.data.id);

        if (customerByIdDetails.success) {
          setSelectedCustomer(customerByIdDetails.data);
          setSelectedVehicle(null);
          setSelectedServices([]);
          setVehicles(customerByIdDetails.data?.vehicle || []);
          setOpenDialog(false);
        } else {
          handleToastClick(customerDetails.message, 'error');
        }
      } else {
        handleToastClick(customerDetails.message, 'error');
      }
    } catch (error) {
      handleApiError(error);
    } finally {
      setLoading(false);
    }
  };

  const submitVehicleDetails = async (vehicleData) => {
    try {
      setLoading(true);
      const savedVehicleData = await saveVehicleDetails(vehicleData);

      if (savedVehicleData.success) {
        setSelectedVehicle(null);
        handleToastClick(savedVehicleData.message, 'success');
        const fetchCustomerByIdData = await getCustomerById(vehicleData.customer_id);

        if (fetchCustomerByIdData.success) {
          setSelectedCustomer(fetchCustomerByIdData.data);
          setVehicles(fetchCustomerByIdData.data?.vehicle || []);
          const selectedVehicleFromData = fetchCustomerByIdData.data?.vehicle.find(
            (vehicle) => vehicle.id === savedVehicleData.data.id
          );
          setSelectedVehicle(selectedVehicleFromData || null);
          setSelectedServices([]);
          setServices([]);
          setSelectedPayments([]);
          if (selectedVehicleFromData) {
            fetchServicesByCategoryIdData(selectedVehicleFromData.category_id.id);
          }
          setAccessoryAmount(0);
          setDiscountAmount(0);
          setOpenVehicleDialog(false);
        } else {
          handleToastClick(fetchCustomerByIdData.message, 'error');
        }
      } else {
        handleToastClick(savedVehicleData.message, 'error');
      }
    } catch (error) {
      handleApiError(error);
    } finally {
      setLoading(false);
    }
  };

  const fetchCustomerData = async () => {
    try {
      setLoading(true);
      const getCustomerDetails = await getCustomerData();

      if (getCustomerDetails.success) {
        setCustomers(getCustomerDetails.data);
      } else {
        handleToastClick(getCustomerDetails.message, 'error');
      }
    } catch (error) {
      handleApiError(error);
    } finally {
      setLoading(false);
    }
  };

  const fetchPaymentData = async () => {
    try {
      setLoading(true);
      const getPaymentDetails = await getPaymentTypes();

      if (getPaymentDetails.success) {
        getPaymentDetails.data.map((data) => (data.amount = 0));
        setPaymentTypes(getPaymentDetails.data);
      } else {
        handleToastClick(getPaymentDetails.message, 'error');
      }
    } catch (error) {
      handleApiError(error);
    } finally {
      setLoading(false);
    }
  };

  const fetchServicesByCategoryIdData = async (categoryId) => {
    try {
      setLoading(true);
      const getServicesDetails = await getServicesByCategoryId(categoryId);

      if (getServicesDetails.success) {
        setServices(getServicesDetails.data);
      } else {
        handleToastClick(getServicesDetails.message, 'error');
      }
    } catch (error) {
      handleApiError(error);
    } finally {
      setLoading(false);
    }
  };

  const handleApiError = (error) => {
    if (error.message === "Not authenticated") {
      navigate("/");
      handleToastClick(error.message, 'error');
    } else {
      console.error(error);
    }
  };

  // Other methods
  const filterOptions = (options, inputValue, inputType) => {
    let filterKeys = [];

    if (inputType === "customer") {
      filterKeys = ["name", "phone_number_one", "phone_number_two", "email", "vehicle", "model_id"];
    } else if (inputType === "vehicle") {
      filterKeys = ["number", "make_id", "model_id"];
    }

    const filteredOptions = options.filter((option) => {
      return filterKeys.some((key) => {
        console.log(key);
        if (typeof option[key] === "string") {
          return option[key].toLowerCase().includes(inputValue.toLowerCase());
        } else if (key === "vehicle" && Array.isArray(option[key])) {
          // Check if option["vehicle"] is an array and filter for vehicle number
          return option[key].some((vehicle) =>
            vehicle.hasOwnProperty("number") &&
            typeof vehicle["number"] === "string" &&
            vehicle["number"].toLowerCase().includes(inputValue.toLowerCase())
          );
        } else if (key === "model_id" && Array.isArray(option["vehicle"])) {
          // Check if option["vehicle"] is an array and filter for vehicle model
          return option["vehicle"].some((vehicle) =>
            vehicle.hasOwnProperty("model_id") &&
            typeof vehicle["model_id"]["name"] === "string" &&
            vehicle["model_id"]["name"].toLowerCase().includes(inputValue.toLowerCase())
          );
        } else if (typeof option[key] === "object" && option[key] !== null) {
          // Handle nested properties
          return Object.values(option[key]).some(
            (nestedVal) =>
              typeof nestedVal === "string" &&
              nestedVal.toLowerCase().includes(inputValue.toLowerCase())
          );
        }
        return false;
      });
    });

    return filteredOptions;
  };

  const handleCustomerChange = (event, newValue) => {
    setSelectedCustomer(newValue);
    setSelectedVehicle(null);
    setSelectedServices([]);
    setServices([]);
    setSelectedPayments([]);
    setVehicles(newValue?.vehicle || []); // Handle null newValue gracefully
    setAccessoryAmount(0);
    setDiscountAmount(0);
  };

  const handleVehicleChange = async (event, newValue) => {
    setSelectedVehicle(newValue);
    setSelectedServices([]);
    setServices([]);
    setSelectedPayments([]);
    if (newValue) {
      fetchServicesByCategoryIdData(newValue.category_id.id);
    }
    setAccessoryAmount(0);
    setDiscountAmount(0);
  };

  const handleServicesChange = (event) => {
    setSelectedServices(event.target.value);
  };

  const handleDelete = (e, value) => {
    e.preventDefault();
    const newSelectedServices = selectedServices.filter(
      (item) => item !== value
    );
    setSelectedServices(newSelectedServices);
  };

  const handleServicesFieldChange = (event, index) => {
    const newServicesAdded = [...selectedServices];
    const serviceValue = event.target.value.replace(/[^0-9.]/g, "");
    newServicesAdded[index] = {
      ...newServicesAdded[index],
      amount: serviceValue,
    };
    setSelectedServices(newServicesAdded);
  };

  const handlePaymentChange = (event, paymentSelected) => {
    const { checked } = event.target;
    const updatedPayments = checked
      ? [...selectedPayments, paymentSelected]
      : selectedPayments.filter((payment) => payment.id !== paymentSelected.id);

    setSelectedPayments(updatedPayments);

    // If unchecked, set the text field value to '0'
    const paymentInputField = document.getElementById(
      `paymentInputField${paymentSelected.id}`
    );
    if (!checked) {
      paymentInputField.value = 0;
    }
  };

  const handlePaymentValueChange = (event, paymentTypeId) => {
    const paymentValue = event.target.value.replace(/[^0-9.]/g, "");
    // Find the index of the payment data with the matching payment type ID
    const index = selectedPayments.findIndex(payment => payment.id === paymentTypeId);
    // If the payment type ID exists, update its amount
    if (index !== -1) {
      const updatedPaymentData = [...selectedPayments];
      updatedPaymentData[index].amount = paymentValue;
      setSelectedPayments(updatedPaymentData);
    }
  };

  const handleAccessoryFieldChange = (event) => {
    const accessoryValue = event.target.value.replace(/[^0-9.]/g, "");
    setAccessoryAmount(accessoryValue);
  };

  const handleDiscountFieldChange = (event) => {
    const discountValue = event.target.value.replace(/[^0-9.]/g, "");
    setDiscountAmount(discountValue);
  };

  // Calculation section
  const calculateServiceTotal = () => {
    const getServiceTotal = selectedServices?.reduce(
      (prev, curr) => prev + parseFloat(curr.amount || 0),
      0
    );
    setServicesTotal(getServiceTotal);
  };

  const calculateOverallTotal = () => {
    const totalValue =
      parseInt(servicesTotal) +
      parseInt(accessoryAmount) -
      parseInt(discountAmount);
    setTotal(totalValue);
  };

  // Form Submission
  const handleBookingSubmit = async () => {
    let hasError = false;
    if (!selectedCustomer) {
      setCustomerError(true);
      hasError = true;
    } else {
      setCustomerError(false);
    }
    if (selectedPayments.length === 0) {
      setPaymentError(true);
      hasError = true;
    } else {
      setPaymentError(false);
      const paidAmount = selectedPayments.map(
        (payments) => Number(payments.amount) || 0
      );
      const totalPaidAmount = paidAmount.reduce(
        (acc, amount) => acc + amount,
        0
      );
      if (totalPaidAmount !== total) {
        setAmountPaidError(true);
        hasError = true;
      } else {
        setAmountPaidError(false);
      }
    }

    if (hasError) {
      return;
    }
    const servicesSelected = selectedServices?.map((service) => {
      return {
        service_id: service.id,
        amount: parseInt(service.amount), // Convert amount to a numeric value if required
      };
    });
    const payments = selectedPayments?.map((payment) => {
      return {
        payment_type_id: payment.id,
        amount: parseInt(payment.amount), // Convert amount to a numeric value if required
      };
    });

    const bookingPayload = {
      customer_id: selectedCustomer.id,
      vehicle_id: selectedVehicle ? selectedVehicle.id : null,
      accessory_amount: accessoryAmount,
      discount: discountAmount,
      total: total,
      services: servicesSelected,
      payment: payments,
    };

    try {
      setLoading(true);
      const bookingDetails = await saveBooking(bookingPayload);
      if (bookingDetails.success) {
        handleToastClick(bookingDetails.message, 'success');
        setSelectedCustomer(null);
        setSelectedVehicle(null);
        setSelectedServices([]);
        setServices([]);
        setSelectedPayments([]);
        setVehicles([]);
        setAccessoryAmount(0);
        setDiscountAmount(0);
      } else {
        handleToastClick(bookingDetails.message, 'error');
      }
    } catch (error) {
      handleApiError(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="container mt-2 bg-white">
      {loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <form className="form-horizontal" onSubmit={(e) => e.preventDefault()}>
          <fieldset>
            <legend>
              <center>
                <Typography
                  className="mt-2 bg-secondary text-white rounded"
                  variant="h3"
                  gutterBottom
                >
                  Royal Car Wash
                </Typography>
              </center>
            </legend>

            <CustomerForm
              open={openDialog}
              onClose={() => setOpenDialog(false)}
              onSubmit={submitCustomerDetails}
            />
            {selectedCustomer && (
              <VehicleForm
                open={openVehicleDialog}
                onClose={() => setOpenVehicleDialog(false)}
                onSubmit={submitVehicleDetails}
                customerId={selectedCustomer.id}
              />
            )}

            <div className="row align-items-center mb-2">
              <div className="col-md-12 inputGroupContainer">
                <TextField
                  id="current-date"
                  label="Date"
                  value={formattedDate}
                  InputProps={{
                    readOnly: true,
                    startAdornment: (
                      <InputAdornment position="start">
                        <CalendarMonthOutlinedIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
            </div>

            <div className="row align-items-center mb-2">
              <div className="col-md-10 py-2 inputGroupContainer">
                <Autocomplete
                  label="Customer"
                  options={customers}
                  getOptionLabel={(option) => option.name}
                  getOptionKey={(option) => option.id}
                  value={selectedCustomer || null}
                  onChange={handleCustomerChange}
                  filterOptions={(options, { inputValue }) =>
                    filterOptions(options, inputValue, "customer")
                  }
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  renderInput={(params) => (
                    <TextField
                      required
                      error={customerError}
                      {...params}
                      key={"customer-" + params.id}
                      label="Select Customer"
                      variant="standard"
                      placeholder="Search Name/Phone Number/Email/Vehicle number"
                      InputProps={{
                        ...params.InputProps,
                        startAdornment: (
                          <InputAdornment position="start">
                            <AccountCircleIcon />
                          </InputAdornment>
                        ),
                      }}
                      fullWidth
                    />
                  )}
                />
              </div>
              <div className="col-md-2 py-2">
                <Button
                  variant="contained"
                  onClick={openCustomer}
                  endIcon={<AddCircleIcon />}
                >
                  Add
                </Button>
              </div>
            </div>

            <div className="row align-items-center mb-2">
              <div
                className={`py-2 inputGroupContainer ${selectedCustomer ? "col-md-10" : "col-md-12"
                  }`}
              >
                <Autocomplete
                  label="Vehicle"
                  options={vehicles}
                  getOptionLabel={(option) =>
                    option.number +
                    " - " +
                    option.make_id.name +
                    "/" +
                    option.model_id.name
                  }
                  value={selectedVehicle || null}
                  onChange={handleVehicleChange}
                  filterOptions={(options, { inputValue }) =>
                    filterOptions(options, inputValue, "vehicle")
                  }
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      key={"vehicle-" + params.id}
                      label="Select Vehicle"
                      variant="standard"
                      placeholder="Search Vehicle Number/Make/Model"
                      InputProps={{
                        ...params.InputProps,
                        startAdornment: (
                          <InputAdornment position="start">
                            <LocalCarWashIcon />
                          </InputAdornment>
                        ),
                      }}
                      fullWidth
                    />
                  )}
                />
              </div>
              {selectedCustomer && (
                <div className="col-md-2 py-2">
                  <Button
                    variant="contained"
                    onClick={openVehicle}
                    endIcon={<AddCircleIcon />}
                  >
                    Add
                  </Button>
                </div>
              )}
            </div>

            <div className="row align-items-center mb-2">
              <div className="col-md-12 py-2 inputGroupContainer">
                <FormControl variant="standard" fullWidth>
                  <InputLabel id="add-services">Select Services</InputLabel>
                  <Select
                    labelId="add-services"
                    multiple
                    displayEmpty
                    value={selectedServices || null}
                    onChange={handleServicesChange}
                    renderValue={(selected) => (
                      <div>
                        {selected?.map((value) => (
                          <Chip
                            color="primary"
                            variant="filled"
                            key={"chip-" + value.id}
                            label={value.name}
                            clickable
                            deleteIcon={
                              <DeleteForeverIcon
                                onMouseDown={(event) => event.stopPropagation()}
                              />
                            }
                            onDelete={(e) => handleDelete(e, value)}
                          />
                        ))}
                      </div>
                    )}
                  >
                    {services?.map((service) => (
                      <MenuItem key={service.id} value={service}>
                        <Checkbox
                          key={"checkbox-" + service.id}
                          checked={selectedServices.some(
                            (selectedservice) =>
                              selectedservice.id === service.id
                          )}
                        />
                        <ListItemText
                          key={"list" + service.id}
                          primary={service.name}
                        />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
              {selectedServices.map((value, index) => (
                <div
                  key={"servicesdiv-" + value.id}
                  className="col-md-2 py-2 inputGroupContainer"
                >
                  <TextField
                    required
                    type="number"
                    id={"selectedServiceField" + value.id}
                    key={"selectedService" + value.id}
                    label={value.name}
                    onBlur={(event) => handleServicesFieldChange(event, index)}
                    variant="standard"
                    defaultValue={value.amount}
                    helperText={
                      "Default Price (" +
                      services.find((service) => service.id === value.id)
                        .amount +
                      ")"
                    }
                    InputProps={{
                      inputProps: { min: "0" },
                      startAdornment: (
                        <InputAdornment position="start">
                          <CurrencyRupeeIcon />
                        </InputAdornment>
                      ),
                    }}
                    fullWidth
                  />
                </div>
              ))}
            </div>

            <div className="row align-items-center mb-2">
              <div className="col-md-4 py-2 inputGroupContainer">
                <TextField
                  type="number"
                  label="Accessory Amount"
                  defaultValue={0}
                  onBlur={(event) => handleAccessoryFieldChange(event)}
                  InputProps={{
                    inputProps: { min: "0" },
                    startAdornment: (
                      <InputAdornment position="start">
                        <HeadphonesBatteryIcon />
                      </InputAdornment>
                    ),
                  }}
                  variant="standard"
                  fullWidth
                />
              </div>
            </div>

            <div className="row align-items-center mb-2">
              <div className="col-md-4 py-2 inputGroupContainer">
                <TextField
                  type="number"
                  label="Discount Amount"
                  defaultValue={0}
                  onBlur={(event) => handleDiscountFieldChange(event)}
                  InputProps={{
                    inputProps: { min: "0" },
                    startAdornment: (
                      <InputAdornment position="start">
                        <CurrencyRupeeIcon />
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">
                        <DiscountOutlinedIcon />
                      </InputAdornment>
                    ),
                  }}
                  variant="standard"
                  fullWidth
                />
              </div>
            </div>

            <div className="row align-items-center mb-2">
              <div className="col-md-12 py-2 inputGroupContainer">
                <FormControl
                  required
                  error={paymentError || amountPaidError}
                  variant="standard"
                >
                  <FormLabel id="payment-type-checkboxes">
                    Payment via..
                  </FormLabel>
                  <FormGroup>
                    <Grid container columnSpacing={{ xs: 1, sm: 2, md: 4 }}>
                      {paymentTypes.map((paymentType) => (
                        <Grid item md={3} xs={12} key={paymentType.id}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={selectedPayments.some(
                                  (payment) => payment.id === paymentType.id
                                )}
                                onChange={(event) =>
                                  handlePaymentChange(event, paymentType)
                                }
                                name={paymentType.name}
                              />
                            }
                            label={paymentType.name}
                          />
                          <TextField
                            id={"paymentInputField" + paymentType.id}
                            fullWidth
                            name={paymentType.name}
                            required
                            type="number"
                            disabled={
                              !selectedPayments.some(
                                (payment) => payment.id === paymentType.id
                              )
                            }
                            label={`Amount Paid with ${paymentType.name}`}
                            InputProps={{
                              inputProps: { min: "0" },
                              startAdornment: (
                                <InputAdornment position="start">
                                  <CurrencyRupeeIcon />
                                </InputAdornment>
                              ),
                            }}
                            variant="standard"
                            onBlur={(event) =>
                              handlePaymentValueChange(event, paymentType.id)
                            }
                          />
                        </Grid>
                      ))}
                    </Grid>
                  </FormGroup>
                  {paymentError && (
                    <FormHelperText>
                      Select Customer preffered payment
                    </FormHelperText>
                  )}
                  {amountPaidError && (
                    <FormHelperText>
                      Kindly enter the correct amount paid
                    </FormHelperText>
                  )}
                </FormControl>
              </div>
            </div>

            <div className="row justify-content-md-center mb-2">
              <div className="col-md-6 py-2 border border-dark rounded">
                <Typography variant="h5" gutterBottom>
                  Order summary
                </Typography>

                <List disablePadding>
                  {servicesTotal > 0 && (
                    <ListItem sx={{ py: 1, px: 0 }}>
                      <ListItemIcon>
                        <CleaningServicesIcon />
                      </ListItemIcon>
                      <ListItemText primary="Services" />
                      <Typography variant="subtitle1" sx={{ fontWeight: 700 }}>
                        {servicesTotal}
                      </Typography>
                    </ListItem>
                  )}
                  {accessoryAmount > 0 && (
                    <ListItem sx={{ py: 1, px: 0 }}>
                      <ListItemIcon>
                        <CarRentalIcon />
                      </ListItemIcon>
                      <ListItemText primary="Accessory" />
                      <Typography variant="subtitle1" sx={{ fontWeight: 700 }}>
                        {accessoryAmount}
                      </Typography>
                    </ListItem>
                  )}
                  {discountAmount > 0 && (
                    <ListItem sx={{ py: 1, px: 0 }}>
                      <ListItemIcon>
                        <RemoveCircleOutlineRoundedIcon />
                      </ListItemIcon>
                      <ListItemText primary="Discount" />
                      <Typography variant="subtitle1" sx={{ fontWeight: 700 }}>
                        ({discountAmount > 0 ? "-" + discountAmount : 0})
                      </Typography>
                    </ListItem>
                  )}
                  <Divider component="li" />
                  <ListItem sx={{ py: 1, px: 0 }}>
                    <ListItemText primary="Overall Amount" />
                    <Typography variant="subtitle1" sx={{ fontWeight: 600 }}>
                      {total}
                    </Typography>
                  </ListItem>
                </List>
              </div>
            </div>
            <div className="row justify-content-end my-2">
              <div className="col-auto">
                <Button variant="contained" onClick={handleBookingSubmit}>
                  Submit
                </Button>
              </div>
            </div>
          </fieldset>
        </form>
      )}
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        open={open}
        onClose={handleToastClose}
        autoHideDuration={5000}
      >
        <Alert
          onClose={handleToastClose}
          severity={severityType}
          variant="filled"
          sx={{ width: "100%" }}
        >
          {message}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default Booking;
