import React, { useState, useEffect, useMemo } from 'react';
import {
    FormControl,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    TextField,
    InputAdornment,
    InputLabel,
    MenuItem,
    Select,
    FormHelperText
} from '@mui/material';
import {
    AccountCircle,
    EmailRounded,
    ContactPhoneRounded,
    HomeRounded
} from '@mui/icons-material';

const CustomerForm = ({ open, onClose, onSubmit }) => {
    const initialCustomerData = useMemo(() => ({
        name: '',
        email: '',
        phone_number_one: '',
        phone_number_two: '',
        address_one: '',
        address_two: '',
        area: '',
        city: 'Chennai',
        state: 'Tamil Nadu',
        pincode: ''
    }), []); // Empty dependency array ensures it's memoized only once
    const [formData, setFormData] = useState(initialCustomerData);
    const fieldLabels = {
        name: 'Name',
        email: 'Email',
        phone_number_one: 'Phone Number 1',
        phone_number_two: 'Phone Number 2',
        address_one: 'Address 1',
        address_two: 'Address 2',
        area: 'Area',
        city: 'City',
        state: 'State',
        pincode: 'Pincode'
    };
    const [formErrors, setFormErrors] = useState({});
    const indianStates = [
        { name: 'Andaman and Nicobar Islands' },
        { name: 'Andhra Pradesh' },
        { name: 'Arunachal Pradesh' },
        { name: 'Assam' },
        { name: 'Bihar' },
        { name: 'Chandigarh' },
        { name: 'Chhattisgarh' },
        { name: 'Dadra and Nagar Haveli and Daman and Diu' },
        { name: 'Delhi' },
        { name: 'Goa' },
        { name: 'Gujarat' },
        { name: 'Haryana' },
        { name: 'Himachal Pradesh' },
        { name: 'Jammu and Kashmir' },
        { name: 'Jharkhand' },
        { name: 'Karnataka' },
        { name: 'Kerala' },
        { name: 'Ladakh' },
        { name: 'Lakshadweep' },
        { name: 'Madhya Pradesh' },
        { name: 'Maharashtra' },
        { name: 'Manipur' },
        { name: 'Meghalaya' },
        { name: 'Mizoram' },
        { name: 'Nagaland' },
        { name: 'Odisha' },
        { name: 'Puducherry' },
        { name: 'Punjab' },
        { name: 'Rajasthan' },
        { name: 'Sikkim' },
        { name: 'Tamil Nadu' },
        { name: 'Telangana' },
        { name: 'Tripura' },
        { name: 'Uttar Pradesh' },
        { name: 'Uttarakhand' },
        { name: 'West Bengal' },
    ];

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
        if (formErrors[name]) {
            setFormErrors({ ...formErrors, [name]: null });
        }
    };

    const handleSubmit = () => {
        const errors = validateForm(formData);
        if (Object.keys(errors).length === 0) {
            onSubmit(formData);
        } else {
            setFormErrors(errors);
        }
    };

    const validateForm = (data) => {
        const requiredFields = ['name', 'phone_number_one', 'area', 'city'];
        const errors = {};
        requiredFields.forEach(field => {
            if (!data[field]) {
                errors[field] = `${capitalizeFirstLetter(field.replace(/_/g, ' '))} is required`;
            }
        });
        return errors;
    };

    const capitalizeFirstLetter = (string) => {
        return string.charAt(0).toUpperCase() + string.slice(1);
    };

    useEffect(() => {
        if (!open) {
            setFormData(initialCustomerData);
            setFormErrors({});
        }
    }, [open, initialCustomerData]);

    return (
        <Dialog open={open} onClose={onClose}>
            {/* Dialog contents */}
            <DialogTitle className="bg-secondary text-white mb-2">Customer Details</DialogTitle>
            <DialogContent>
                <form autoComplete="off">
                    {/* Form fields */}
                    {Object.keys(formData).map((field) => {
                        let startAdornment = null;

                        // Define the icon for each field
                        switch (field) {
                            case 'name':
                                startAdornment = <InputAdornment position="start"><AccountCircle /></InputAdornment>;
                                break;
                            case 'email':
                                startAdornment = <InputAdornment position="start"><EmailRounded /></InputAdornment>;
                                break;
                            case 'phone_number_one':
                            case 'phone_number_two':
                                startAdornment = <InputAdornment position="start"><ContactPhoneRounded /></InputAdornment>;
                                break;
                            case 'address_one':
                            case 'address_two':
                            case 'area':
                            case 'city':
                                startAdornment = <InputAdornment position="start"><HomeRounded /></InputAdornment>;
                                break;
                            default:
                                startAdornment = null;
                                break;
                        }
                        if (field !== 'state' && field !== 'pincode') {
                            return (
                                <TextField
                                    className="mb-2"
                                    key={field}
                                    name={field}
                                    label={fieldLabels[field]}
                                    variant="standard"
                                    color="primary"
                                    fullWidth
                                    value={formData[field]}
                                    onChange={handleChange}
                                    error={!!formErrors[field]}
                                    helperText={formErrors[field]}
                                    InputProps={{
                                        startAdornment: startAdornment,
                                    }}
                                    required={['name', 'phone_number_one', 'area', 'city'].includes(field)}
                                    autoComplete="off"
                                />
                            );
                        }
                        return null; // Skip rendering the field for 'state'
                    })}
                    {/* Select field for state */}
                    <FormControl variant="standard" color="primary" fullWidth error={!!formErrors.state}>
                        <InputLabel id="select-state-label">Select State</InputLabel>
                        <Select
                            className="mb-2"
                            name="state"
                            label="Select State *"
                            labelId="select-state-label"
                            id="select-state"
                            value={formData.state}
                            onChange={handleChange}
                            aria-describedby="select-state-error"
                        >
                            {indianStates.map((state, index) => (
                                <MenuItem key={index} value={state.name}>
                                    {state.name}
                                </MenuItem>
                            ))}
                        </Select>
                        <FormHelperText error id="select-state-error">{formErrors.state}</FormHelperText>
                    </FormControl>
                    {/* Pincode field */}
                    <TextField
                        className="mb-2"
                        name="pincode"
                        label="Pincode"
                        variant="standard"
                        color="primary"
                        fullWidth
                        value={formData.pincode}
                        onChange={handleChange}
                        error={!!formErrors.pincode}
                        helperText={formErrors.pincode}
                        InputProps={{
                            startAdornment: (<InputAdornment position="start"><HomeRounded /></InputAdornment>),
                        }}
                        autoComplete="off"
                    />
                </form>
            </DialogContent>
            {/* Dialog actions */}
            <DialogActions>
                <Button color="error" onClick={onClose}>Cancel</Button>
                <Button variant="contained" onClick={handleSubmit}>Submit</Button>
            </DialogActions>
        </Dialog>
    );
};

export default CustomerForm;
