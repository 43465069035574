const LOGIN_REGISTRATION_URL = process.env.REACT_APP_API_URL;
const BASE_URL = process.env.REACT_APP_API_BASE_URL;
let token = null || localStorage.getItem('token');

const handleResponse = async (response) => {
    if (!response.ok) {
        if (response.status === 409 || response.status === 400) {
            const data = await response.json();
            return data;
        }
        throw new Error('Request failed');
    }

    const data = await response.json();
    return data;
};

const getAuthHeaders = () => ({
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`,
});

export const login = async (username, password) => {
    const response = await fetch(`${LOGIN_REGISTRATION_URL}/login`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ username, password }),
    });

    if (!response.ok) {
        throw new Error('Login failed');
    }

    const data = await response.json();
    token = data.data.token; // Save the token
    return data.data;
};

export const saveCustomerDetails = async (customerData) => {
    const response = await fetch(`${BASE_URL}/customer`, {
        method: 'POST',
        headers: getAuthHeaders(),
        body: JSON.stringify(customerData)
    });

    return handleResponse(response);
};

export const saveVehicleDetails = async (VehicleData) => {
    const response = await fetch(`${BASE_URL}/vehicle`, {
        method: 'POST',
        headers: getAuthHeaders(),
        body: JSON.stringify(VehicleData)
    });

    return handleResponse(response);
};

export const saveBooking = async (bookingData) => {
    const response = await fetch(`${BASE_URL}/bill`, {
        method: 'POST',
        headers: getAuthHeaders(),
        body: JSON.stringify(bookingData)
    });

    return handleResponse(response);
};

export const getCustomerData = async () => {
    if (!token) {
        throw new Error('Not authenticated');
    }

    const response = await fetch(`${BASE_URL}/customer`, {
        method: 'GET',
        headers: getAuthHeaders(),
    });

    return handleResponse(response);
};

export const getCustomerById = async (id) => {
    if (!token) {
        throw new Error('Not authenticated');
    }

    const response = await fetch(`${BASE_URL}/customer/${id}`, {
        method: 'GET',
        headers: getAuthHeaders(),
    });

    return handleResponse(response);
};

export const getCategoryData = async () => {
    if (!token) {
        throw new Error('Not authenticated');
    }

    const response = await fetch(`${BASE_URL}/category`, {
        method: 'GET',
        headers: getAuthHeaders(),
    });

    return handleResponse(response);
};

export const getServicesByCategoryId = async (catergoryId) => {
    if (!token) {
        throw new Error('Not authenticated');
    }

    const response = await fetch(`${BASE_URL}/servicesByCategoryId/${catergoryId}`, {
        method: 'GET',
        headers: getAuthHeaders(),
    });

    return handleResponse(response);
};

export const getPaymentTypes = async () => {
    if (!token) {
        throw new Error('Not authenticated');
    }

    const response = await fetch(`${BASE_URL}/paymentType`, {
        method: 'GET',
        headers: getAuthHeaders(),
    });

    return handleResponse(response);
};

export const getVehiclesMakeByCategoryId = async (catergoryId) => {
    if (!token) {
        throw new Error('Not authenticated');
    }

    const response = await fetch(`${BASE_URL}/getVehicleMakes/${catergoryId}`, {
        method: 'GET',
        headers: getAuthHeaders(),
    });

    return handleResponse(response);
};

export const getVehiclesModels = async () => {
    if (!token) {
        throw new Error('Not authenticated');
    }

    const response = await fetch(`${BASE_URL}/model`, {
        method: 'GET',
        headers: getAuthHeaders(),
    });

    return handleResponse(response);
};