// src/Login.js
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { login } from '../services/api';
import './Login.scss'; // Import the SCSS file

const Login = () => {
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        username: '',
        password: '',
    });
    const [error, setError] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            // Handle successful login, e.g., store tokens, navigate to billng, etc.
            const userData = await login(formData.username, formData.password);
            localStorage.setItem('token', userData.token);
            navigate('/booking');
        } catch (error) {
            setError('Invalid username or password');
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    useEffect(() => {
        document.body.classList.add('login-page');
        document.title = 'Royal Car Wash / Login';
        // Remove the class when the component unmounts
        return () => {
            document.body.classList.remove('login-page');
        };
    }, []);

    return (
        // Main Component
        <div className="card p-3 logindiv">
            <div className="row align-items-center two-columns">
                <div className='col-12 col-sm-12 col-md-4 text-center text-white rounded py-2 logo-column'>
                    <img className="logo" alt="car logo" src="logo.png" />
                    <h1>Royal Car Wash</h1>
                    <h2>Chennai's best car cleaning service</h2>
                </div>
                <div className='col-12 col-sm-12 col-md-7 offset-md-1 py-2'>
                    <form className='mx-auto w-md-50' onSubmit={handleSubmit}>
                        <h2 className="text-center">Login</h2>
                        <div className="form-group my-2">
                            <label className="form-label" htmlFor="username">Username</label>
                            <input
                                type="text"
                                className="form-control form-control-lg"
                                id="username"
                                name="username"
                                value={formData.username}
                                onChange={handleChange}
                                required
                            />
                        </div>
                        <div className="form-group my-2">
                            <label className="form-label" htmlFor="password">Password</label>
                            <input
                                type="password"
                                className="form-control form-control-lg"
                                id="password"
                                name="password"
                                value={formData.password}
                                onChange={handleChange}
                                required
                            />
                        </div>
                        <input className="btn btn-primary btn-block btn-lg w-100" type="submit" value="Log In"></input>
                        {error && <p>{error}</p>}
                    </form>
                </div>
            </div>
        </div>
    );
};

export default Login;
